<template>
  <div class="img-upload">
    <el-upload :action="prefix"
               :file-list="uploadList"
               :data="imgData"
               :before-upload="beforeUpload"
               :on-preview="handlePictureCardPreview"
               :on-success="onSuccess"
               :on-remove="onRemove"
               list-type="picture-card">
      <i class="el-icon-plus"></i>
      <div slot="file"
           slot-scope="{file}">
        <img class="el-upload-list__item-thumbnail"
             :src="file.url"
             alt="">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span class="el-upload-list__item-delete"
                @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible"
               :close-on-click-modal='false'
               append-to-body>
      <img width="100%"
           :src="dialogImageUrl"
           alt="">
    </el-dialog>
    <p class="input-tip"
       v-if="pixel">{{pixel}}</p>
  </div>
</template>

<script>
import { getCos, imgUpload } from '@/api'
export default {
  name: '',
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: [Number, String, Array],
    pixel: String
  },
  data () {
    return {
      prefix: '',
      imgData: {},
      imgPostData: {},
      dialogVisible: false,
      dialogImageUrl: '',
      uploadList: [],
      disabled: false
    }
  },
  watch: {
    fileList: {
      immediate: true, // 这句重要
      handler (val) {
        this.uploadList = val
      }
    }
  },
  components: {
  },
  methods: {
    // 计算签名
    async getAuthorization () {
      const res = await getCos({
        type: 'ACTIVITY_PICTURES'
      })
      if (res.meta.code === 0) {
        this.imgPostData = res.data
        const AuthData = res.data
        this.prefix = 'https://' + AuthData.Bucket + '.cos.' + AuthData.Region + '.myqcloud.com/'
        this.imgData = {
          Key: AuthData.FilePath + '/' + AuthData.Key,
          Signature: AuthData.Authorization
          // 'x-cos-security-token': AuthData.XCosSecurityToken
        }
      }
    },
    async beforeUpload (file) {
      await this.getAuthorization()
    },
    async onSuccess (response, file, fileList) {
      file.url = this.prefix + this.imgData.Key

      const res = await imgUpload({
        type: this.type,
        key: this.imgPostData.Key,
        path: this.imgPostData.FilePath,
        driver: 'COS'
      })
      if (res.meta.code === 0) {
        this.dialogImageUrl = res.data.images[0]
        file.id = res.data.images[0].id
        if (this.multiple) {
          this.$emit('input', [...this.value, file.id])
          this.dispatch('ElFormItem', 'el.form.change', [...this.value, file.id])
        } else {
          fileList = [file]
          this.$emit('input', file.id)
          this.dispatch('ElFormItem', 'el.form.change', file.id)
        }
        this.uploadList = fileList
        this.$emit('on-success', this.uploadList)
      }
    },
    handleRemove (file) {
      if (this.multiple) {
        const fileIndex = this.uploadList.findIndex((item) => {
          return item.id === file.id
        })
        this.uploadList.splice(fileIndex, 1)
        const ids = []
        this.uploadList.map((item) => {
          ids.push(item.id)
        })
        this.$emit('input', ids)
        this.dispatch('ElFormItem', 'el.form.change', ids)
      } else {
        this.uploadList = []
        this.$emit('input', null)
        this.dispatch('ElFormItem', 'el.form.change', null)
      }
    },
    onRemove (file, fileList) {
      if (this.multiple) {
        const ids = []
        fileList.map((item) => {
          ids.push(item.id)
        })
        this.$emit('input', ids)
        this.dispatch('ElFormItem', 'el.form.change', ids)
      } else {
        fileList = []
        this.$emit('input', null)
        this.dispatch('ElFormItem', 'el.form.change', null)
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // elementUI mixins - emitter 中拷贝的
    dispatch (componentName, eventName, params) {
      var parent = this.$parent || this.$root
      var name = parent.$options.componentName

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent

        if (parent) {
          name = parent.$options.componentName
        }
      }
      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(params))
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scope>
.img-upload {
  .el-dialog {
    z-index: 9999999 !important;
  }
  .el-upload--picture-card {
    width: 70px;
    height: 70px;
    line-height: 79px;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 70px;
    height: 70px;
  }
}
// .el-dialog__wrapper {
//   z-index: 99999 !important;
// }
</style>
